html {
  scroll-behavior: smooth;
}

body,
html {
  background: #f7f7f7;
  font-family: "Roboto", sans-serif;
  color: #212529;
  min-height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "roboto-condensed", "Roboto", sans-serif;
}
a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}
.text-muted {
  color: #46484a !important;
}
.w-50 {
  width: 50% !important;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-54 {
  padding-left: 54px;
  padding-right: 54px;
}
.pt-36 {
  padding-top: 36px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mb-18 {
  margin-bottom: 18px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-28 {
  margin-bottom: 28px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-34 {
  margin-bottom: 34px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-54 {
  margin-top: 54px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.h-86 {
  height: 86px !important;
}
.btn-login-group .btn {
  width: 167px;
  height: 48px;
  padding: 0.688rem 23px;
}
.btn-secondary {
  background-color: #f5f9fc;
  border-color: #f5f9fc;
  border-radius: 6px;
  color: #00529a;
  font-weight: 500;
  padding: 0.688rem 23px;
  min-width: 122px;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background-color: #00529a;
  border-color: #00529a;
  color: #f5f9fc;
}
.btn-secondary:focus,
.btn-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgb(0 82 154 / 50%);
}
.btn {
  border-radius: 6px;
}
.btn-primary {
  background-color: #00529a;
  border-color: #00529a;
  color: #ffffff;
  font-weight: 500;
  padding: 0.688rem 23px;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #004989;
  border-color: #004989;
  color: #f5f9fc;
}
.btn-primary:focus,
.btn-primary:active:focus,
.btn-outline:focus,
.btn-outline:active:focus,
.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgb(134 197 255 / 30%);
}
.btn-outline-danger {
  color: #ee3d42;
  border-color: #ee3d42;
}
.btn-outline {
  border-color: #00529a;
  color: #00529a;
  font-weight: 400;
}
.btn-outline:hover,
.btn-outline-secondary:hover {
  background-color: #00529a;
  color: #fff;
  border-color: #00529a;
}
.btn-outline:hover svg path {
  stroke: #fff;
}
.btn-outline:hover svg.cart_svg path {
  fill: #fff;
}
.btn-outline-secondary {
  color: #00529a;
  border-color: rgb(28 81 185 / 30%);
  padding: 0.375rem 1rem;
}
.btn-danger {
  background-color: #ee3d42;
  border-color: #ee3d42;
}
.btn-w-162 {
  width: 162px;
}
.btn-grid li {
  width: 50%;
}
.btn-grid .btn {
  width: 100%;
}
.f500 {
  font-weight: 500;
}
.f400 {
  font-weight: 400 !important;
}
.f300 {
  font-weight: 300;
}
.ln24 {
  line-height: 24px !important;
}

/* form elements */

label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.form-group {
  margin-bottom: 34px;
}
.form-control {
  border: 1px solid #f2f2f2;
  height: 48px;
  padding: 7px 13px;
  border-radius: 6px;
  color: #212529;
}
.form-control::-moz-placeholder {
  color: #8899a8;
}
.form-control::-webkit-placeholder {
  color: #8899a8;
}
.form-control::placeholder {
  color: #8899a8;
}
.form-control:focus {
  border-color: #00529a;
  box-shadow: 0 0 0 0.25rem rgb(134 197 255 / 30%);
}
.form-control.error {
  border-color: #dc3545;
  box-shadow: none;
}
input:disabled.form-control {
  background-color: #f5f9fc;
  border-color: #f5f9fc;
}
label.error {
  margin: 0;
  font-weight: 400;
  color: #dc3545;
  margin-top: 0.25rem;
  font-size: 14px;
}
label.errorCard {
  margin: 0;
  font-weight: 400;
  color: #dc3545;
  margin-top: 0.25rem;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.form-control.valid {
  border-color: #198754;
  box-shadow: none;
  background: url("../img/done.svg") no-repeat;
  background-position: right 10px center;
}
select.form-control {
  background: url("../img/select_arrow.svg") no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}
.table-responsive::-webkit-scrollbar {
  height: 1px;
}
.table-responsive::-webkit-scrollbar-track {
  background-color: rgb(0 0 0 / 20%);
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #000;
}
/* header */
header {
  background-color: #ffffff;
}
header > .container {
  padding: 13px 15px;
}
.navbar {
  background: linear-gradient(94.4deg, #0052cc 26.2%, #034fbf 92.95%);
  padding: 0;
}
.navbar-expand-md .navbar-nav .nav-link {
  font-weight: 500;
  color: #ffffff;
  padding: 33px 0;
  position: relative;
}
.dropdown-toggle img {
  width: 25px;
  margin-right: 5px;
}
.navbar-expand-md .navbar-nav .nav-link:not(.dropdown-toggle)::before {
  content: "";
  width: 100%;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: -1px;
  left: 0;
  transition: all 0.3s ease-in;
  transform-origin: center;
  transform: scaleX(0);
}
.navbar-expand-md .navbar-nav .nav-link.active::before,
.navbar-expand-md .navbar-nav .nav-link:hover::before {
  transform: scaleX(1);
}
.navbar-nav:first-child {
  margin-left: 45px;
}
.navbar-expand-md .navbar-nav .nav-item:not(:last-child) {
  margin-right: 46px;
}
.none-focus:focus {
  box-shadow: none !important;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: unset;
  content: "";
  border: 0;
  background: url(../img/dropdown-arrow.svg) no-repeat;
  width: 12px;
  height: 12px;
  background-position: center;
}
.dropdown-menu[data-bs-popper] {
  top: 75px;
  left: auto;
  margin-top: 0;
  right: 0;
  padding: 0.75rem 0;
}
.dropdown-menu {
  min-width: 215px;
  margin: 0;
  text-align: left;
  border: 0;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border-radius: 8px;
}
.dropdown-item {
  padding: 0.5rem 20px;
  font-weight: 500;
  border: 0;
  position: relative;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #212529;
  background-color: #f7f7f7;
}
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus {
  background-color: #00529a;
  color: #fff;
}
.dropdown-item::after {
  content: "";
  background: url(../img/caret-right.svg) no-repeat;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.dropdown-item:active::after,
.dropdown-item:focus::after,
.dropdown-item.active::after {
  filter: brightness(20);
}
.page-alert {
  background: #ee3d42;
  display: flex;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
  color: #fff;
  margin: 0;
  border: 0;
  border-radius: 0;
}
.alert svg {
  margin-right: 7px;
}
.alert a {
  color: inherit;
  text-decoration: underline;
}
.alert-dismissible .btn-close {
  background: transparent;
  opacity: 1;
  box-shadow: none;
  outline: none;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.alert.min-alert {
  display: inline-block;
  padding: 8px 17px;
  font-size: 14px;
  margin-bottom: 12px;
  border: 0;
  background: #ffffff;
  border-radius: 6px;
  color: #ee3d42;
  height: 38px;
  font-weight: 500;
}
.error-alert {
  background: #ee3d42;
}
.info-alert {
  background: #17a2b8;
}
.success-alert {
  background: #28a745;
}
.warning-alert {
  background: #ffc107;
}
/* authentication */

.authentication_box {
  max-width: 489px;
  width: calc(100% - 30px);
  background: #ffffff;
  border-radius: 8px;
  margin: 106px auto 0;
  padding: 60px;
}
.register_box {
  max-width: 636px;
  margin: 68px auto 0;
}
.authentication_box form {
  margin: 0;
}
.forgot_pwd {
  color: #46484a;
  text-align: center;
  display: block;
  margin-top: 20px;
  font-size: 14px;
}
.register_link {
  color: #00529a;
  text-align: center;
  display: block;
  margin-top: 20px;
  font-size: 14px;
}
.register_box .row {
  margin-left: -7px;
  margin-right: -7px;
}
.register_box .row > div {
  padding-left: 7px;
  padding-right: 7px;
}

/* breadcrumb */

.breadcrumb-bar {
  background: linear-gradient(94.4deg, #0052cc 26.2%, #034fbf 92.95%);
  border-top: 1px solid rgb(234 233 237 / 30%);
  padding: 50px 0;
}
.breadcrumb-item {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.breadcrumb-item a {
  color: #fff;
  opacity: 0.5;
}
.breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  opacity: 0.5;
}
/* dashboard */

.transaction {
  background-color: rgba(28, 81, 185, 0.05);
  padding: 42px 0;
}
.card {
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  padding: 28px 40px 43px;
  border: 0;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  min-height: 395px;
}
.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}
.type {
  max-width: 140px;
}

.sm-title {
  font-size: 16px;
  font-weight: 500;
}

.md-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.002em;
}
.lg-title {
  font-size: 16px;
  line-height: 28px;
}
.xl-title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}
.dark-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #46484a;
}
.primary-text {
  color: #00529a;
}
.price {
  font-weight: 400;
  font-size: 24px;
}
.remaining_meal {
  display: block;
  text-align: center;
  font-weight: 300;
  font-size: 25px;
  margin: 20px 0;
}
.remaining_meal.my-70 {
  margin: 70px 0;
}
.btn-outline img {
  vertical-align: top;
}
.remaining_meal span {
  font-weight: 400;
  font-size: 72px;
  line-height: 71px;
  color: #00529a;
  display: block;
}
.table.transaction_table {
  margin-top: 17px;
}
.table > :not(:first-child) {
  border-top: none;
}
.transaction_table th {
  color: #717171;
  font-size: 12px;
  text-transform: uppercase;
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
.transaction_table td {
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
.transaction_table th:not(:first-child),
.transaction_table td:not(:first-child) {
  text-align: right;
}
.transaction_table td:nth-child(2) {
  font-weight: 300;
}
.px-40 {
  padding: 0 40px;
}
.restaurant_list {
  padding: 43px 0 114px;
}
.restaurant_table {
  width: 100%;
}
.restaurant_modal {
  justify-content: space-between;
}
.restaurant_name {
  display: flex;
  align-items: center;
}
.restaurant_name img {
  margin-right: 20px;
  width: 90px;
}
.restaurant_name h5 {
  font-size: 16px;
  margin-bottom: 1px;
}
.restaurant_name p {
  font-size: 14px;
  margin: 0;
}
.restaurant_table td {
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 14px;
}
.restaurant_table td:first-child {
  padding-left: 40px;
}
.restaurant_table td:last-child {
  padding-right: 40px;
  text-align: right;
}
.restaurant_table td:nth-child(3) {
  text-align: right;
}
.img-link {
  margin-left: 35px;
  opacity: 0;
}
.list-inline.btn-grid li.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}
.restaurant_table tr {
  cursor: pointer;
}
.restaurant_table tr:hover,
.restaurants_table tr:hover {
  background-color: rgba(30, 83, 185, 0.05);
}
.restaurant_table tr:hover .img-link {
  opacity: 1;
}
.restaurants_table .restaurant_item {
  padding: 0 2.5rem;
  cursor: pointer;
}
.restaurants_table .restaurant_content {
  border-bottom: 1px solid #e6e6e6;
  padding: 12px 0;
}
.restaurant_item:last-child .restaurant_content {
  border-bottom: 0;
}
.restaurants_table .restaurant_item:hover {
  background-color: rgba(30, 83, 185, 0.05);
}
.website_link {
  color: #00529a;
}
.ocmp_ad_box {
  background: #ee3d42 url("../img/pattern.png") no-repeat;
  /* background: #EE3D42; */
  border-radius: 6px;
  padding: 42px 32px;
  background-position: bottom;
  color: #fff;
  display: flex;
  flex-direction: column;
  background-size: contain;
}
.ocmp_ad_box.blue_box {
  background: #0052b3 url("../img/pattern.png") no-repeat;
  background-size: contain;
  background-position: bottom;
  max-height: 440px;
}
.ocmp_ad_box h2 {
  font-size: 34px;
  line-height: 42px;
}
.ocmp_ad_box p {
  margin-top: 11px;
  font-size: 18px;
}
.ocmp_ad_box.horizontal_ad {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #ee3d42;
  background-position: bottom right;
  background-size: contain;
  margin-bottom: 24px;
}
.ocmp_ad_box.horizontal_ad .btn-manage {
  margin-top: 0;
  margin-bottom: 10px;
  max-width: 174px;
}

.ocmp_ad_box.horizontal_ad .btn-manage-service {
  width: 125px;
  margin-left: 0;
  display: block;
  max-width: 125px;
}
.btn-toolbar {
  justify-content: center;
  display: flex;
}

.btn-manage,
.btn-manage-service {
  padding: 10px;
  background: #fff;
  /* width: 50%; */
  color: #00529a;
  font-weight: 500;
  border-radius: 6px;
  border-color: #fff;
  margin-top: 64px;
  margin-right: 10px;
}
.btn-manage-service-padding {
  padding-left: 15px;
}
.notification-bar {
  padding: 12px 15px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border-radius: 6px;
  text-align: center;
  margin: 12px 0;
  font-weight: 500;
  text-transform: uppercase;
}
.red-icon {
  width: 32px;
  height: 32px;
  text-align: center;
  background: #ee3d42;
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
  margin-right: 11px;
}
.reference_box {
  padding: 0 35px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border-radius: 6px;
  min-height: 205px;
  height: 100%;
  display: flex;
  align-items: center;
}
.reference_box > div {
  width: 100%;
}
.reference_box .btn-outline-secondary {
  min-width: 151px;
}
/* footer */

footer a {
  color: #212529;
}
footer a:hover {
  color: #00529a;
}
footer .container {
  padding-bottom: 19px;
}
footer .container hr:not([size]) {
  background: #eae9ed;
  opacity: 1;
  margin-bottom: 19px;
  margin-top: 0;
}
footer li.list-inline-item:not(.social_icon) {
  margin-right: 35px;
}
footer li.list-inline-item.social_icon {
  margin-right: 16px;
}
.sticky_menu {
  background: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 15px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  z-index: 999;
  box-shadow: 0 -2px 10px rgb(0 0 0 / 5%);
}
.sticky_menu a {
  color: #46484a;
}
.sticky_menu a.active {
  color: #00529a;
}
.sticky_menu a.active svg path {
  stroke: #00529a;
}
.sticky_menu svg {
  display: block;
  margin: 0 auto 10px;
}
.v-bottom {
  vertical-align: bottom;
}
/* dashboard-register */
.dashboard-register {
  padding: 54px 0 153px;
}
.dashboard-register form {
  max-width: 703px;
}
.dashboard-register .lg-title {
  margin-bottom: 11px;
}
.dashboard-register .lg-title + p {
  margin-bottom: 20px;
}
.dashboard-register .card {
  padding: 42px 51px 52px;
  margin-bottom: 20px;
}
.dashboard-register .row,
#editProfile .row,
#editPayment .row {
  margin-left: -6px;
  margin-right: -6px;
}
.dashboard-register .row > div,
#editProfile .row > div,
#editPayment .row > div {
  padding-left: 6px;
  padding-right: 6px;
}
.dashboard-register .btn-primary {
  width: 278px;
  float: right;
}

/* get-started */

.get-started {
  background-color: rgba(28, 81, 185, 0.05);
  padding: 42px 0;
}
.get-started-banner {
  background-color: #fff;
  background-image: url("../img/dine.svg"), url("../img/cloud.svg"),
    url("../img/star.svg");
  background-repeat: no-repeat;
  max-width: 1296px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  padding: 104px 0 94px;
  background-position: 13% 27%, 100% 14%, 88% 100%;
}
.get-started-banner > div {
  max-width: 608px;
  margin: 0 auto;
  text-align: center;
  color: #00529a;
}
.get-started-banner h1 {
  font-size: 64px;
  font-weight: 300;
  margin-bottom: 12px;
}
.get-started-banner h5 {
  margin-bottom: 46px;
}
.get-started-banner .btn-outline {
  width: 265px;
}
.get-started-banner .list-inline-item:not(:last-child) {
  margin-right: 0.75rem;
}
.no-restaurant {
  background: rgba(230, 238, 245, 0.51);
  border-radius: 6px;
  padding: 83px 20px 61px;
}
.no-restaurant .btn-primary {
  max-width: 318px;
  margin: 0 auto;
  display: block;
}

/* modal */
.modal-header {
  border: 0;
  padding: 26px;
  justify-content: flex-end;
}
.modal-header .btn-close {
  padding: 0;
  margin: 0;
  width: 12px;
  height: 12px;
  background-size: 12px;
  box-shadow: none;
  z-index: 99;
}
.modal-content {
  border: 0;
  border-radius: 8px;
}
.modal-content .form-group {
  margin-bottom: 1rem;
}

.max-width-172 {
  max-width: 172px;
}
.min-width-240 {
  min-width: 240px;
}
.min-width-400 {
  min-width: 400px;
}
.max-width-300 {
  max-width: 300px;
}
.max-width-350 {
  max-width: 350px;
  margin: 0 auto;
}
.max-width-424 {
  max-width: 424px;
}
.max-width-575 {
  max-width: 575px;
  margin: 0 auto;
}
.max-width-500 {
  max-width: 500px;
  margin: 0 auto;
}
.modal-content h2 {
  margin-bottom: 11px;
}
.modal-content h6 {
  margin-bottom: 35px;
}
.w100 {
  width: 100%;
}
.modal-body {
  padding: 1rem 1rem 70px;
}
.modal-body .btn-primary {
  margin-top: 17px;
}
.success-msg {
  color: #28a745;
}
.success_animation {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}
.admin-address a {
  display: block;
  color: #00529a;
  text-decoration: underline;
}
.active-card-toast {
  background: rgb(0 82 154 / 9%);
  border-radius: 4px;
  color: #00529a;
  font-weight: 500;
  display: inline-block;
  padding: 9px 24px;
  margin-top: 56px;
  transform: skew(-15deg);
  width: max-content;
}
.active-card-toast span {
  transform: skew(15deg);
  display: block;
}
.mini-card {
  max-width: 306px;
  padding: 27px 38px 36px;
  height: auto;
}
.mini-card .btn-primary {
  height: 38px;
  margin-bottom: 18px;
  padding: 0.3rem 23px;
}
.mini-card .btn-outline {
  margin-bottom: 10px;
}
.transaction.mycard {
  padding: 48px 0 147px;
}
.no_fund_title {
  font-size: 36px;
  color: #00529a;
  line-height: 54px;
  font-weight: 400;
}
input[type="radio"] {
  display: none;
}
.d-flex.radioButtonGroup {
  column-gap: 12px;
}
.radioButton input + label {
  padding: 7px 10px;
  background: rgba(0, 82, 154, 0.05);
  border-radius: 6px;
  font-size: 16px;
  color: #005bab;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.radioButton input:checked + label {
  background-color: #00529a;
  color: #fff;
}
.radioButtonGroup > div {
  width: 100%;
}
.btn-update-address {
  width: 230px;
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #46484a;
  padding-left: 25px;
}
input[type="checkbox"] + label::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
}
input[type="checkbox"]:checked + label::before {
  background: #00529a url("../img/check.svg") no-repeat;
  background-position: center;
  border-color: #00529a;
}
.btn-update-address,
.btn-form-actions {
  height: 38px;
  padding: 5px 23px;
  margin-top: 1rem;
  width: 150px;
  margin-right: 10px;
}
.btn-update-address {
  width: 230px;
}
.disable {
  opacity: 0.3;
  pointer-events: none;
}
.address_fields {
  padding: 0;
}
/* .selectedShippingAddress{
    display: none;
} */

/* transaction */

.transaction_history {
  padding: 55px 0;
}
.transaction_history .card {
  padding: 40px;
  height: auto;
}
.transaction_history .card:not(:last-child) {
  margin-bottom: 24px;
}
.transaction_history .table th,
.payment-table th {
  border-bottom: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #717171;
  padding: 0 0 4px;
}
.transaction_history .table th:not(:first-child),
.transaction_history .table td:not(:first-child),
.payment-table th:not(:first-child),
.payment-table td:not(:first-child) {
  text-align: right;
}
.transaction_history .table td,
.payment-table td {
  padding: 14px 0;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid #d9d9d9;
}
.payment-table td {
  vertical-align: middle;
  border-bottom: 0;
}
.payment-table tr:not(:last-child) td {
  border-bottom: 1px solid #d9d9d9;
}
.transaction_history .table td:first-child {
  width: 180px;
}
.transaction_history .table td:nth-child(3),
.transaction_history .table td:nth-child(4) {
  width: 150px;
}
.transaction_download_table tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.transaction_download_table thead tr {
  background-color: #00529a !important;
  color: #fff;
}
.transaction_download_table tr th,
.transaction_download_table tr td {
  padding: 6px 4px;
}

.transaction_download_table tr td {
  color: #717171;
}
.btn-view {
  max-width: 375px;
  width: 100%;
  margin-top: 10px;
}
.transaction_history .btn-primary {
  font-weight: 400;
  padding: 0.378rem 23px;
}
#receiptRange {
  background: rgba(0, 82, 154, 0.05);
  border-radius: 6px;
  width: 234px;
  height: 38px;
  color: #00529a;
  font-size: 16px;
  padding: 7px 10px;
}
#receiptRange span {
  vertical-align: middle;
}
.messages {
  padding: 55px 0 126px;
}
.messages .card {
  padding: 32px 40px 60px;
}
.messages .form-group {
  width: 245px;
}
.messages .form-control {
  border: 1px solid #d9d9d9;
}
.message-table th {
  background: rgba(0, 82, 154, 0.05) !important;
  border: 0;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  padding-left: 0;
}
.message-table th:first-child {
  border-radius: 4px 0 0 4px;
  padding-left: 15px;
  width: 290px;
}
.message-table th:last-child {
  border-radius: 0 4px 4px 0;
}
.message-table td {
  padding: 17px 0 45px 0;
  line-height: 24px;
  border: 0;
}
.message-table tr:not(:last-child) td {
  border-bottom: 1px solid #d9d9d9;
}
textarea.form-control {
  height: auto;
  resize: none;
}

/* help */
.help {
  padding: 76px 0 83px;
}
.help .card {
  font-weight: 500;
}
.help .card h6 {
  color: #00529a;
}
.help .card a {
  color: #212529;
}
.accordion-card {
  padding: 48px 40px 43px;
}
.accordion-item {
  background-color: rgb(242 246 250);
  border: 0;
  border-radius: 4px;
  margin-bottom: 12px;
}
.accordion-button:not(.collapsed),
.accordion-button {
  color: #212529;
  background-color: rgb(242 246 250);
  box-shadow: none;
  font-weight: 500;
}
.accordion-body {
  padding: 0 28px 38px;
  line-height: 24px;
  font-weight: 400;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 17px 28px;
  font-size: 20px;
  border-radius: 4px !important;
  border: 0;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}
.accordion-button::after {
  background-image: url("../img/arrow.svg");
  background-position: center;
}
.contact-us {
  padding: 58px 0 99px;
}
.contact-us .form-content {
  max-width: 712px;
  margin: 0 auto;
}
.contact-us .card {
  padding: 54px 20px;
}

/* settings */

.settings {
  padding: 58px 0 94px;
}
.settings .card {
  padding: 30px 30px 30px 60px;
  margin-bottom: 24px;
  height: auto;
}
.divider {
  padding-top: 13px;
  margin-bottom: 16px;
  border-bottom: 1px solid #d9d9d9;
  max-width: 230px;
}
.btn-edit:hover svg path {
  stroke: #ffffff;
}
.btn-edit svg {
  margin-left: 2px;
  width: 24px;
  top: -1px;
  position: relative;
  vertical-align: bottom;
}
.badge-primary {
  color: #00529a;
  background: rgb(0 82 154 / 5%);
  box-shadow: none;
  border-radius: 4px;
}
.make_primary {
  font-size: 14px;
  font-weight: 400;
  color: #00529a;
  float: right;
}
input[type="checkbox"].switchToggle {
  display: none;
}
input[type="checkbox"].switchToggle + label::before {
  content: none;
}
.switchToggle + label {
  cursor: pointer;
  text-indent: -9999px;
  width: 48px;
  height: 24px;
  background: transparent;
  display: block;
  border-radius: 100px;
  position: relative;
  border: 1px solid #d9d9d9;
}
.switchToggle + label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 3px;
  width: 18px;
  height: 18px;
  background: #d9d9d9;
  border-radius: 90px;
  transition: 0.3s;
}
input.switchToggle:checked + label {
  background: #00529a;
  border-color: #00529a;
}
input.switchToggle:checked + label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
  background-color: #fff;
}
.switchToggle + label:active:after {
  width: 30px;
}
.plan-card {
  padding: 10px;
}
.plan-card-hover:hover {
  border: 2px solid rgba(0, 82, 154, 0.7) !important;
  border-radius: 6px;
}
.meal-title {
  font-size: 24px;
  font-weight: 300;
  color: #00529a;
  margin-top: 72px;
  margin-bottom: 4px;
}
.planSelection .radio {
  height: 100%;
}
.planSelection label {
  display: block;
  height: 100%;
}
.planSelection label .card {
  border: 2px solid #ffffff;
  height: 100%;
  margin-bottom: 24px;
  cursor: pointer;
}
.planSelection input:checked + label .card {
  border: 2px solid #00529a;
}
.transaction.mycardAddFund {
  padding: 60px 0 64px;
}
/* mealPlan */
.mealPlan {
  padding: 58px 0 118px;
}
.mealPlan .borderLeft {
  border-left: 1px solid rgb(0 0 0 / 20%);
}
.miniMeal {
  color: #00529a;
  font-size: 24px;
}
.totalMeal {
  color: #00529a;
  font-size: 40px;
}
.mealDetail {
  padding-left: 40px;
}
.mealPlan .card {
  padding: 30px 43px;
  height: auto;
  margin-bottom: 24px;
}
.mealPlan .form-control {
  border-color: #d9d9d9;
}
.mealPlan .form-control:focus {
  border-color: #00529a;
}
.blue_box .meal-title {
  color: #fff;
  margin-top: 0;
  line-height: 28px;
}
.mealPlan .radioButton input + label {
  width: 150px;
}
.mealPlan .radioButton {
  margin-bottom: 34px;
}
.ocmp_ad_box.blue_box {
  height: 100%;
}
.ocmp_ad_box.blue_box .btn-manage {
  margin-top: 120px;
}
.ordercomplete {
  padding: 53px 0 80px;
}
.ordercomplete .btn-primary {
  padding: 0.4rem 16px;
}
.h-auto {
  height: auto !important;
}
.card.order-summary {
  padding: 60px 60px 100px;
}
.transaction-list ul {
  margin-bottom: 26px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  text-align: right;
}
.transaction-list li li {
  display: flex;
  color: #717171;
  font-weight: 500;
  justify-content: space-between;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.transaction-list li li span {
  color: #212529;
  display: block;
  text-transform: initial;
}
.tempCardBg {
  background-color: #f5f9fc;
}
.tempMargin {
  margin: -70px -15px 100px;
}
.tempMargin .img-wrapper,
.tempCardPrint .img-wrapper {
  position: relative;
  width: 370px;
  height: 233px;
}
.tempMargin .img-content {
  background: url("../../../public/img/tempCard.svg") no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -65px;
  background-size: contain;
  background-position: center;
}
.tempCardPrint h5 {
  position: absolute;
  bottom: -3.75rem;
  font-size: 14px;
  color: #00529a;
  left: 1rem;
}
.tempCardPrint h6 {
  position: absolute;
  top: 4.25rem;
  font-size: 14px;
  color: #00529a;
  right: 1rem;
}

.printed-card h5 {
  position: absolute;
  bottom: 0.25rem;
  font-size: 14px;
  color: #00529a;
  left: 1rem;
}
.printed-card h6 {
  position: absolute;
  top: 0.25rem;
  font-size: 14px;
  color: #00529a;
  right: 1rem;
}

.all_cards {
  padding: 80px 0;
}
.card-padding {
  padding: 20px 20px 28px;
}
.cardName {
  font-weight: 700;
  color: #004989;
}
.cardNumber {
  color: #e83338;
  font-size: 24px;
}
.card > .md-title .price span {
  text-transform: none;
}
.text-danger {
  color: #ee3d42;
}
[data-bs-toggle="tooltip"] {
  cursor: pointer;
}
.restaurants {
  padding: 55px 0 24px;
}
/* .get-started-banner {
  background-image: url(../img/dine-light.svg), url(../img/cloud-light.svg),
    url(../img/star-light.svg);
  background-position: 13% 27%, 100% 14%, 88% 100%;
  position: relative;
}
.get-started-banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, #0052b3, #0052b3);
}
.get-started-banner div {
  position: relative;
  z-index: 999;
  color: #fff;
} */
.filter {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 6px;
  padding: 30px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.v-top {
  vertical-align: top;
}
.view_layout.nav-tabs .nav-link {
  margin-bottom: 0;
  border: 0;
  border-radius: 6px;
  color: #00529a;
}
.view_layout.nav-tabs {
  border-bottom: 0;
  margin-bottom: 24px;
  justify-content: flex-end;
  position: relative;
  z-index: 99;
}
#listView .card {
  padding: 20px 85px;
}
#mapView .restaurants_table td {
  padding-top: 9px;
  padding-bottom: 9px;
}
.restaurants_table td {
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 14px;
  border-bottom: 1px solid #d9d9d9;
  vertical-align: top;
  padding-left: 10px;
}
.restaurants_table .restaurant_name img {
  width: 75px;
}
.restaurants_table tr:last-child td {
  border-bottom: 0;
}
.restaurants_table td:last-child {
  vertical-align: middle;
}
.restaurants_table a:not(.btn) {
  color: #212529;
}
.restaurants_table .btn {
  width: 128px;
}
.restaurants_table .btn:not(:last-child) {
  margin-bottom: 10px;
}
input[type="checkbox"].favRestaurant + label {
  height: 25px;
  cursor: pointer;
}
input[type="checkbox"].favRestaurant + label::before {
  border: 0;
  background: url(../img/default.svg) no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
}
input[type="checkbox"].favRestaurant:checked + label::before {
  background: url(../img/active.svg) no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
}
.suggest-friend-block .btn-manage {
  max-width: 235px;
  margin-top: 30px;
}
.suggest-friend-block .get-started-banner h1 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 16px;
}
.suggest-friend-block .get-started-banner {
  position: relative;
  background: linear-gradient(0deg, #0052b3, #0052b3);
  padding: 60px 0;
  margin-bottom: 80px;
}
.suggest-friend-block .get-started-banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, #0052b3, #0052b3);
  background-image: url(../img/dine-light.svg), url(../img/cloud-light.svg),
    url(../img/star-light.svg), url(../img/circle.svg);
  background-position: 13% 27%, 88% 100%, 100% 14%, 0% 85%;
  background-repeat: no-repeat;
}
.btn-filter {
  font-size: 24px;
  color: #212529;
  padding: 16px;
  margin-bottom: 24px;
  margin-top: 24px !important;
  position: relative;
  z-index: 99;
}
.map_wrapper {
  height: 620px;
  background: #fff;
  padding: 8px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border-radius: 6px;
  position: sticky;
  top: 6rem;
}
.map_wrapper iframe {
  border-radius: 6px;
}
#mapView .card {
  padding: 0 40px 10px;
}
.mapPopup {
  position: absolute;
  background: #ffffff;
  border-radius: 6px;
  padding: 24px 30px;
  left: 24px;
  bottom: 24px;
  width: 460px;
  z-index: 999;
}
.f8 {
  font-size: 8px !important;
}
.f10 {
  font-size: 10px;
}
.f14 {
  font-size: 14px;
}
.mapPopup a:not(.btn),
#moreDetail a:not(.btn) {
  color: #212529;
}
.mapPopup .btn,
#moreDetail .btn {
  margin-top: 12px;
  padding: 0.375rem 0.75rem;
}
.mapPopup .restaurant_name img,
#moreDetail .restaurant_name img {
  margin-right: 20px;
  width: 70px;
}
.modalClose {
  position: absolute;
  right: 10px;
  top: 10px;
}
.modalClose li {
  vertical-align: middle;
}
#moreDetail .modal-body {
  padding: 18px 30px 32px;
}
#moreDetail .modal-header {
  padding: 12px 8px 8px;
}
#moreDetail iframe {
  height: 204px;
}
#moreDetail .btn-close {
  position: absolute;
  top: 22px;
  right: 15px;
  padding: 8px;
  background-color: #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
}
.active-card-toast {
  font-weight: 400;
  padding: 3px 14px;
  margin-top: 6px;
  width: 100%;
}
.black-text {
  color: #000;
}
.loading {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 1366px) {
  .btn-edit {
    padding: 0.375rem 0.5rem;
  }
  .card.order-summary {
    padding: 40px 40px 60px;
  }
  .min-width-400 {
    min-width: 340px;
  }
  .min-width-240 {
    min-width: 200px;
  }
}

.center-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .mealPlan .col-lg-9 {
    width: 70%;
  }
  .mealPlan .col-lg-3 {
    width: 30%;
  }
  .ad_black_row .col-xl-8 {
    width: 67.666667%;
  }
  .ad_black_row .col-xl-4 {
    width: 32.333333%;
  }
  .dropdown-direction {
    transform: translate(-58px, 52px) !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .pe-md-36 {
    padding-right: 36px !important;
  }
  #tempCard .modal-dialog {
    max-width: 575px;
  }
  .dropdown-direction {
    transform: translate(-58px, 52px) !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .modal-lg.modal-dialog {
    max-width: 709px;
  }
  .modal-dialog.modal-md {
    max-width: 580px;
  }
  .pe-md-36 {
    padding-right: 25px !important;
  }
  #tempCard .modal-dialog {
    max-width: 575px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 545px;
  }
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
  .mealPlan .radioButton input + label {
    width: 130px;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .map_wrapper {
    min-height: 500px;
    margin-top: 24px;
  }
  #listView .card {
    padding: 20px;
  }
  .min-width-400 {
    min-width: 320px;
  }
  .min-width-240 {
    min-width: 140px;
  }
  .filter_title {
    min-width: 120px;
  }
  .filter li:not(:last-child) {
    margin-bottom: 16px;
  }
  .ocmp_ad_box.horizontal_ad .btn-manage {
    width: 150px;
    margin-left: auto;
    display: block;
  }
  .ocmp_ad_box.horizontal_ad .btn-manage-service {
    width: 125px;
    margin-left: 0;
    display: block;
    max-width: 125px;

  }
  .btn-toolbar {
    justify-content: center;
    display: flex;
  }

  .btn-w-162 {
    width: 130px;
    padding: 0.375rem 0.4rem;
    font-size: 14px;
  }
  .card-padding .md-title,
  .card-padding .md-title .price {
    font-size: 18px;
  }
  .remaining_meal span {
    font-size: 50px;
    line-height: 50px;
  }
  .cardNumber {
    font-size: 20px;
    line-height: 20px;
  }
  .transaction_history .table td:nth-child(3) {
    width: 100px;
  }
  .transaction_history .table td:first-child {
    width: 130px;
  }
  .transaction_history .table td:nth-child(4) {
    width: auto;
  }
  .mealDetail {
    padding-left: 20px;
  }
  /* .plan-card {
    padding: 24px;
  } */
  .meal-title,
  .totalMeal {
    font-size: 36px;
  }
  .plan-card .md-title {
    font-size: 16px;
    line-height: 20px;
  }
  .settings .xl-title {
    font-size: 24px;
    line-height: 30px;
  }
  .settings .d-flex img {
    width: 40px;
  }
  .settings .card {
    padding: 30px;
  }
  .breadcrumb-item {
    font-size: 24px;
    line-height: 30px;
  }
  .no_fund_title {
    font-size: 32px;
    line-height: 40px;
  }
  .mb-60 {
    margin-bottom: 40px !important;
  }
  .breadcrumb-bar {
    padding: 30px 0;
  }
  .reference_box {
    padding: 0 22px;
    height: 250px;
    position: relative;
    overflow: hidden;
  }
  .reference_box img {
    position: absolute;
    bottom: 0;
    right: -30px;
    z-index: 0;
  }
  .refer_friend img {
    width: 214px;
  }
  .suggest_restaurant img {
    width: 185px;
  }
  .get-started-banner {
    padding: 60px 20px;
  }
  .get-started-banner h1 {
    font-size: 48px;
  }
  .get-started-banner h5 {
    margin-bottom: 30px;
    font-size: 16px;
  }
  .get-started-banner {
    background-size: 80px, 35px, 80px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .min-h-lg-0 {
    min-height: 0;
  }
  .ocmp_ad_box {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 27px 32px;
    background: #ee3d42 url("../img/pattern-tablet.png") no-repeat;
    background-size: contain;
    background-position: bottom right;
  }
  .ocmp_ad_box.blue_box {
    background: #0052b3 url("../img/pattern-tablet.png") no-repeat;
    background-size: contain;
    background-position: bottom right;
  }
  .ocmp_ad_box.horizontal_ad {
    justify-content: space-between;
  }
  .ocmp_ad_box.blue_box .btn-manage {
    margin-top: 0;
  }
  .blue_box .meal-title {
    white-space: nowrap;
    margin-bottom: 0 !important;
  }
  .ocmp_ad_box .btn-manage {
    width: 184px;
    margin: 0;
  }
  .ocmp_ad_box br {
    display: none;
  }
  .ocmp_ad_box p {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 2px;
  }
  .ocmp_ad_box h2 {
    margin-bottom: 1rem;
  }
  .reference_box {
    align-items: flex-start !important;
    padding-top: 23px;
  }
  .reference_box .mb-4 {
    margin-bottom: 16px !important;
  }
  .reference_box .btn-outline-secondary {
    position: relative;
    z-index: 99;
  }
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .restaurants_table .restaurant_name img {
    width: 45px;
  }
  .restaurants_table .btn {
    width: 110px;
    font-size: 14px;
  }
  .restaurants_table td:first-child {
    width: 180px;
  }
  .filter {
    flex-flow: wrap;
  }
  .filter_title {
    min-width: 40%;
    margin-bottom: 20px;
  }
  .filter .d-flex {
    width: 100%;
  }
  .filter .d-flex > * {
    min-width: 48%;
  }
  .transaction_history .table td,
  .payment-table td {
    font-size: 14px;
    padding: 10px 4px;
  }
  .transaction_history .card {
    padding: 25px;
  }
  .meal_plan_box .col-lg-4 p {
    margin-top: 16px;
  }
  .mealPlan .card {
    padding: 25px;
  }
  .meal_plan_box br {
    display: none;
  }
  .contact_box .card {
    padding: 20px 10px;
    height: 100%;
    margin-bottom: 0;
  }
  .contact_box .col-lg-3 {
    margin-bottom: 20px;
  }
  .accordion-body {
    padding: 0 20px 25px;
    font-weight: 400;
  }
  .accordion-button {
    padding: 16px 20px;
    font-size: 18px;
  }
  .accordion-card {
    padding: 25px;
  }
  .alert-dismissible {
    padding-right: 5px;
  }
  .ocmp_ad_box {
    margin-top: 0;
  }
  .card {
    height: auto;
    margin-bottom: 20px;
  }
  .transaction {
    padding: 22px 0 2px;
  }
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
  .restaurant_list {
    padding: 22px 0 93px;
  }
  .notification-bar {
    margin: 20px 0;
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row > div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar-nav:first-child {
    margin-left: 26px;
  }
  .navbar-expand-md .navbar-nav .nav-item:not(:last-child) {
    margin-right: 32px;
  }
  .px-40 {
    padding: 0 24px;
  }
  .restaurant_table td:first-child {
    padding-left: 24px;
  }
  .restaurant_table td:last-child {
    padding-right: 24px;
  }
  .img-link {
    margin-left: 24px;
  }
  .settings .d-flex.align-items-center {
    margin-bottom: 15px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  #moreDetail .modal-body {
    padding: 18px 20px 32px;
  }
  /* #moreDetail .modal-dialog {
    margin: 0;
  } */
  #moreDetail .modal-content {
    border-radius: 0;
  }
  #moreDetail .f14 {
    font-size: 13px;
  }
  .mapPopup .restaurant_name {
    flex-flow: wrap;
  }
  .mapPopup .restaurant_name .deliveryAvailable img {
    margin-right: 20px;
    width: 24px;
  }
  .view_layout.nav-tabs .nav-link {
    background: rgba(255, 255, 255, 0.37);
    backdrop-filter: blur(18px);
  }
  .view_layout.nav-tabs .nav-link.active {
    backdrop-filter: none;
    background-color: #fff;
  }
  .view_layout.nav-tabs {
    justify-content: space-between;
  }
  .mapPopup {
    left: 15px;
    right: 15px;
    width: auto;
    bottom: 10px;
  }
  #mapView {
    height: calc(100vh - 210px);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
  .restaurants .col-md-12 {
    margin-top: -24px;
    position: relative;
  }
  .map_wrapper {
    min-height: 100%;
    margin-top: 0;
    padding: 0;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
  }
  #mapView > div,
  #mapView .col-md-6 {
    height: 100%;
  }
  #mapView .loading {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .suggest-friend-block .get-started-banner > div {
    text-align: left;
    padding: 0 35px;
  }
  .suggest-friend-block .get-started-banner {
    padding: 40px 0;
    margin-bottom: 75px;
  }
  .suggest-friend-block .get-started-banner::before {
    background-position: -12% 82%, 120% 100%, 120% 14%, 0% 115%;
  }
  .suggest-friend-block .btn-manage {
    max-width: 209px;
    margin-top: 120px;
  }
  .view_layout.nav-tabs li {
    width: 48%;
    text-align: center;
  }
  .ocmp_ad_box.horizontal_ad * {
    width: 100%;
  }
  .ocmp_ad_box.horizontal_ad .btn-manage {
    width: 100%;
    margin-left: 0;
    display: block;
    max-width: 100%;
  }
  .ocmp_ad_box.horizontal_ad .btn-manage-service {
    width: 40%;
    margin-left: 0;
    display: block;
    max-width: 40%;
  }

  .ocmp_ad_box h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .ocmp_ad_box p {
    margin-top: 6px;
    font-size: 14px;
  }
  .btn-toolbar {
    justify-content: center;
    display: flex;
  }

  .restaurants {
    padding: 24px 0;
    min-height: calc(100vh - 184px);
    overflow: hidden;
  }
  .filter .d-flex {
    flex-direction: column-reverse;
    margin-bottom: 35px;
  }
  .filter {
    flex-flow: wrap;
    flex-direction: column-reverse;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 9999;
    margin: 0;
    padding: 40px 20px;
    align-items: flex-start;
    border-radius: 0;
    display: none;
  }
  .filter-backdrop {
    content: "";
    background: rgba(0, 9, 26, 0.56);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: 99;
  }
  .filter .input-group {
    margin-bottom: 16px;
  }
  .filter .list-inline {
    width: 100%;
  }
  .filter li:not(:last-child) {
    margin-bottom: 35px;
  }
  .ocmp_ad_box.horizontal_ad {
    justify-content: start;
    align-items: start;
    flex-flow: wrap;
    padding: 25px;
  }
  .filter li {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .card-padding li {
    width: 100%;
    margin-top: 15px;
  }
  .card-padding .md-title,
  .card-padding .md-title .price {
    font-size: 16px;
  }
  .btn-w-162 {
    width: 100%;
    padding: 0.375rem 0.4rem;
    font-size: 16px;
  }
  .tempMargin {
    margin: -40px -15px 80px;
  }
  .tempMargin img {
    bottom: -50px;
    max-width: 85%;
  }
  .card.order-summary {
    padding: 25px 20px 40px;
  }
  .mealPlan .borderLeft {
    border-left: 0;
  }
  .mealDetail {
    padding-left: 0;
    padding-top: 25px;
    border-top: 1px solid rgb(0 0 0 / 20%);
    margin-top: 25px;
  }
  .max-width-172 {
    max-width: 100%;
  }
  .meal-title {
    margin-top: 30px;
    font-size: 28px;
  }
  .planSelection label .card {
    padding: 20px;
  }
  .btn-login-group .btn {
    width: 90px;
    height: 40px;
    padding: 0.5rem 0;
    font-size: 14px;
  }
  #editPayment .table-responsive {
    margin-bottom: 20px;
  }
  .modal-header {
    padding: 20px 20px 0;
  }
  .modal-body {
    padding: 1rem 1rem 30px;
  }
  .settings .card {
    padding: 25px 20px;
    margin-bottom: 20px;
  }
  .settings .col-sm-6:not(:last-child) {
    margin-bottom: 15px;
  }
  .settings .xl-title {
    font-size: 20px;
    line-height: 24px;
  }
  .settings .d-flex img {
    width: 35px;
  }
  .btn-edit {
    margin-top: 20px;
  }
  .mini-card {
    max-width: 100%;
  }
  .transaction_history .card {
    padding: 20px;
  }
  .radioButton input + label {
    padding: 7px 5px;
    font-size: 14px;
  }
  .md-title {
    font-size: 18px;
    line-height: 24px;
  }
  .no_fund_title {
    font-size: 28px;
    line-height: 36px;
  }
  .active-card-toast {
    padding: 10px 16px;
    font-size: 14px;
    margin-top: 40px;
  }
  .transaction .mb-60 {
    margin-bottom: 40px !important;
  }
  .remaining_meal span {
    font-size: 52px;
    line-height: 55px;
  }
  .admin-address {
    margin-bottom: 20px;
  }
  .px-40 {
    padding: 0 20px;
  }
  .mb-30 {
    margin-bottom: 20px !important;
  }
  .modal-body br {
    display: none;
  }
  .get-started-banner .list-inline-item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .get-started-banner h5 {
    margin-bottom: 20px;
    line-height: 24px;
  }
  .get-started-banner {
    background-position: 1% 1%, 100% 40%, 50% 100%;
  }
  .get-started-banner h1 {
    font-size: 36px;
  }
  .dashboard-register .btn-primary {
    width: 215px;
  }
  .breadcrumb-item {
    font-size: 14px;
    line-height: 24px;
  }
  .breadcrumb-bar {
    padding: 20px 0;
  }
  .dashboard-register .card {
    padding: 20px;
  }
  .alert-dismissible {
    padding-right: 5px;
    padding-left: 0;
  }
  .page-alert,
  .alert.min-alert {
    font-size: 12px;
  }
  .page-alert .text-center {
    text-align: left !important;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .restaurant_table table,
  .restaurant_table tbody,
  .restaurant_table tr,
  .restaurants_table table,
  .restaurants_table tbody,
  .restaurants_table tr {
    display: block;
  }
  .restaurant_table tr,
  .restaurants_table tr {
    padding: 20px;
    display: flex;
    flex-flow: wrap;
    width: 100%;
    justify-content: space-between;
    position: relative;
  }
  .restaurants_table tr {
    padding: 30px 20px 25px;
  }
  .restaurants_table td:last-child {
    vertical-align: middle;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #listView .card {
    padding: 0;
    margin: 0;
  }
  .btn-contact {
    background: rgba(0, 82, 154, 0.05);
    border-radius: 6px;
    padding: 7px 10px;
    width: 100%;
    height: 38px;
    text-align: center;
    color: #00529a;
    display: block;
  }
  .restaurant_table td:nth-child(3) {
    order: 13;
  }
  .restaurant_table td:nth-child(2),
  .restaurants_table td:nth-child(2) {
    display: none;
  }
  .img-link {
    display: none;
  }
  .restaurant_table td:nth-child(3),
  .restaurant_table td:last-child,
  .restaurants_table td:nth-child(6),
  .restaurants_table td:nth-child(7) {
    display: inline-block;
    padding: 0 !important;
    width: calc(50% - 8px);
  }
  .restaurants_table td:nth-child(3),
  .restaurants_table td:nth-child(5),
  .restaurants_table td:nth-child(4) {
    display: none;
  }
  .restaurants_table td {
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
  }
  .restaurant_table td:last-chil,
  .restaurants_table td:last-child {
    margin-right: 16px;
  }
  .restaurants_table .restaurant_name .deliveryAvailable img {
    width: auto;
    margin-left: 10px;
  }
  .restaurant_table td:first-child,
  .restaurants_table td:first-child {
    padding-left: 0;
    display: block;
    padding-bottom: 20px;
    padding-top: 0;
    width: 100%;
    padding-right: 0 !important;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 0.5rem 0;
    display: block;
    font-size: 18px;
  }
  .navbar-expand-md .navbar-nav .nav-link:not(.dropdown-toggle)::before {
    /* margin: 0 auto;
        right: 0;
        left: 0; */
    content: none;
  }
  .navbar-expand-md .navbar-nav li.nav-item {
    /* padding: 0 20px; */
  }
  .nav-item .nav-link {
    padding: 0.5rem 20px !important;
  }
  .nav-item .nav-link::after,
  .nav-item .dropdown-item::after{
    right: 20px;
  }
  .navbar-nav:first-child {
    margin-left: 0;
    margin-top: 50px;
  }
  .signout-text {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 20px;
    position: absolute;
    width: 100%;
    bottom: 20px;
  }
  .navbar-expand-md .navbar-nav .nav-item:not(:last-child) {
    margin-right: 0;
  }
  footer {
    padding-bottom: 76px;
  }
  .container {
    padding: 0 16px;
  }
  .card {
    height: auto;
    margin-bottom: 20px;
    padding: 25px 20px 40px;
  }
  .remaining_meal,
  .remaining_meal.my-70 {
    margin: 60px 0;
  }
  .btn-grid.d-flex {
    display: block !important;
  }
  .btn-grid li {
    width: 100%;
  }
  .btn-grid li {
    margin-bottom: 1rem;
  }
  .list-inline.btn-grid li.list-inline-item:not(:last-child) {
    margin-right: 0;
  }
  td,
  th {
    white-space: normal;
  }
  td:not(:last-child),
  th:not(:last-child) {
    padding-right: 30px;
  }
  .transaction .table-responsive {
    margin-bottom: 30px;
  }
  .ocmp_ad_box {
    padding: 35px 22px 60px;
  }
  .notification-bar {
    font-size: 14px;
    text-transform: inherit;
    padding: 12px;
    font-weight: 400;
  }
  .red-icon {
    margin-right: 6px;
  }
  .btn-manage,
  .ocmp_ad_box.blue_box .btn-manage {
    margin-top: 9px;
  }
  .reference_box {
    padding: 20px;
    height: 170px;
    margin-bottom: 20px;
    align-items: start;
  }
  .reference_box .lg-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px !important;
  }
  .refer_friend img {
    width: 177px;
  }
  .suggest_restaurant img {
    width: 160px;
  }
  .reference_box .btn-outline-secondary,
  .reference_box .lg-title {
    position: relative;
    z-index: 9;
  }
  footer li.social_icon {
    display: none;
  }
  footer li.list-inline-item:not(.social_icon) {
    margin-right: 0;
  }
  footer .page_link {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .copyright {
    display: flex;
    justify-content: space-between;
  }
  footer .social_icon li.list-inline-item {
    margin-right: 16px;
  }
  .restaurant_name h5 {
    margin-bottom: 4px;
  }
  .restaurant_name img {
    width: 60px;
  }
  .restaurant_name p {
    line-height: 21px;
  }
  .restaurant_table td:last-child a {
    margin-right: 11px;
  }
  .restaurant_table tr,
  .restaurants_table tr {
    border-bottom: 1px solid #d9d9d9;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: fixed;
    height: 100% !important;
    top: 0;
    width: 100%;
    right: -100%;
    z-index: 9999;
    transition: all 0.3s linear;
    background: linear-gradient(94.4deg, #0052cc 26.2%, #034fbf 92.95%);
    padding-top: 70px;
  }
  .navbar-collapse.show {
    right: 0;
  }
  .navbar-collapse.collapsing {
    height: 100% !important;
  }
  .navbar-brand {
    position: relative;
    z-index: 99999;
  }
  .navbar-toggler {
    padding: 0;
    position: relative;
    z-index: 99999;
  }
  .navbar-brand img {
    width: 85px;
  }
  .navbar {
    padding: 10px 0;
  }
  header .dropdown-menu {
    display: block !important;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  .dropdown-item {
    padding: 0.5rem 20px;
    font-size: 18px;
    color: #fff;
  }
  .dropdown-toggle {
    display: none;
  }
  .dropdown-item::after,
  header .nav-link::after {
    content: "";
    background: url(../img/caret-right.svg) no-repeat;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    filter: brightness(150);
  }
  .navbar-toggler svg path {
    transition: all 0.3s ease-in-out;
  }
  .navbar-toggler:not(.collapsed) svg path:nth-child(2) {
    transform: rotate(45deg) translate(7px, -7px);
  }
  .navbar-toggler:not(.collapsed) svg path:last-child {
    transform: rotate(-45deg) translate(-17px, -1px);
  }
  .navbar-toggler:not(.collapsed) svg path:first-child {
    opacity: 0;
  }
  .dashboard-register {
    padding: 22px 0 93px;
  }
  #receiptRange {
    margin-bottom: 1rem;
    width: 100%;
  }
  .transaction_history .btn-primary {
    padding: 0.378rem 1rem;
    width: 100%;
  }
  .transaction_history .button-list li {
    width: 100%;
    margin: 0;
  }
  .transaction_history .button-inline-list li {
    width: 48%;
  }
  .transaction_history .button-inline-list .btn-primary {
    padding: 0.378rem 7px;
  }
  .messages,
  .contact-us,
  .transaction_history,
  .mealPlan,
  .transaction.mycardAddFund {
    padding: 40px 0;
  }
  .messages .card {
    padding: 25px 20px 40px;
  }
  .message-table th:first-child {
    width: 90px;
  }
  .message-table td {
    white-space: normal;
  }
  .contact-us .card {
    padding: 25px 20px;
  }
  .ocmp_ad_box.blue_box {
    height: auto;
    margin-top: 20px;
  }
  .w-84 {
    width: 84% !important;
  }
  .center-spinner {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .authentication_box {
    padding: 24px;
    margin-top: 30px;
  }
  .register_box {
    margin: 30px auto 0;
  }
  .medium_title {
    font-size: 24px;
    line-height: 30px;
  }
  .header-logo {
    width: 86px;
  }
  .divider {
    max-width: 100%;
  }
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moredetails__modal {
  max-width: 545px;
}
.flex-1 {
  flex: 1;
}
.table-responsive table tr:last-child td {
  border: none;
}
.transaction-list li:last-child ul {
  border: none;
}
.fadeOut {
  opacity: 0;
  transition: opacity 0.1s;
}
.fadeIn {
  opacity: 1;
  transition: opacity 0.1s;
}
.min-h-0 {
  min-height: 0;
}
@media (min-width: 1200px) {
  .min-h-xl-100 {
    height: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .tempMargin h5 {
    bottom: -2.5rem;
  }
  .tempMargin h6 {
    top: 6.25rem;
  }
}

.page-404 {
  padding: 58px 0 94px;
}

.box-404.card {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.box-404 h1 {
  max-width: 600px;
  margin: 0 auto 2rem;
}

.box-404 .btn {
  max-width: 300px;
  margin: 0 auto;
}

.saved-credit {
  margin-top: 2rem;
}
.primary-color {
  color: #00529a;
}
.form-control div[class$="-control"] {
  height: 100%;
  border: none;
}

.card-item {
  flex: 1;
}
.card-plan-description {
  margin-top: auto;
}
.cursor-pointer {
  cursor: pointer;
}

/* Additional CSS */
/*@import url(https://lp.ocmp.com/portal/assets/css/portal-overrides.css);*/

.print-source {
  display: none;
}
body > .print-source {
  display: block;
}
@media print {
  body {
    border: none;
  }
  .print-source {
    display: block;
    height: 100%;
  }
}

.qrCode {
  box-sizing: content-box;
  max-width: 260px;
  max-height: 260px;
  border: 1px solid rgba(0, 0, 0, 1);
  margin: 0 auto 15px;
  padding: 2px;
}

@media (max-width: 575.98px) {
  .qrCode {
    max-width: 250px;
    max-height: 250px;
  }
}

@media (max-width: 991.98px) {
  .gap-xs {
    gap: 0 !important;
  }
}

@media (max-width: 991.98px) {
  .mb-xs-3 {
    margin-bottom: 20px !important;
  }
}
