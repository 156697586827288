/* Portal Overrides CSS */
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:700&display=swap);


body {bordertop:5px solid red;}

h1,h2,h3,h4,h5,h6,.breadcrumb-item {
    font-family: 'Roboto Condensed', roboto, sans-serif !important;
}


.breadcrumb-bar {
    padding:10px 0 !important;
}

li.breadcrumb-item {
    font-size: 28px;
}



#error-area .mb-4 {
    border:1px solid red;
    background: pink;
    border-radius:5px;
    padding:10px;
    margin-bottom:0px !important;
}


#error-area label{
    margin-bottom:10px;
    font-size: 18px !important
}

#error-area label:last-child{
    margin-bottom:0px;
}




.card.min-h-0.meal_plan_box > p, .card.min-h-0.meal_plan_box .col-lg-4:nth-child(1), .card.min-h-0.meal_plan_box .col-lg-4:nth-child(2) {
    display:none !important;
}



/*
.card.min-h-0.meal_plan_box .col-lg-4:nth-child(3):before {
	content: "AND / OR";
	font-weight: bold;
}

.card.min-h-0.meal_plan_box .col-lg-4:nth-child(3) p {
	padding-top: 20px;
}

.card.min-h-0.meal_plan_box .col-lg-4:nth-child(3) {
	width: 50%;
	padding-top: 20px;
}


*/

.ocmp_ad_box {
    padding: 10px 32px 18px 32px !important;
    background-image: none !important;
}




.card .price {
    display:none !important;
}
